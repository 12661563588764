import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useRouter } from 'next/router';

import { FC, useMemo, useState } from "react"

import { Button } from "src/components/common/button"
import { create } from "src/helpers/bem"

import { getPage } from 'src/helpers/pages';
import { useLocale } from "src/states/locale"
import { TranslationMessages } from "src/translations"
import { PagesProps } from "src/types/SharedPageProps"

import { xRMApiTermsOfService } from 'src/types/xRM';

import styles from "./ShopCartCheckout.module.scss"
import { BillingOptions, ShopCartCheckoutBillingOptionsCard, defaultBillingOptions } from './ShopCartCheckoutBillingOptionsCard';
import { ShopCartCheckoutSummary } from './ShopCartCheckoutSummary';
import { ShopCartCheckoutTermsOfServicesCard } from './ShopCartCheckoutTermsOfServicesCard';

const bem = create(styles, "ShopCartCheckout")

export type ShopCartCheckoutProps = {
	messages: TranslationMessages
	pages: PagesProps
}

export const ShopCartCheckout: FC<ShopCartCheckoutProps> = ({ messages, pages }) => {
	const locale = useLocale()
	const router = useRouter()
	const [ termsOfServices, setTermsOfServices ] = useState<xRMApiTermsOfService[]>([])
	const [ billingOptions, setBillingOptions ] = useState<BillingOptions>(defaultBillingOptions)
	const m = messages.pages.shop.cart.checkout

	const webshopCartOverviewPage = useMemo(() => getPage("Webshop Cart Overview Template", locale, pages), [locale, pages])

	return (
		<div className={bem()}>
			<Button
				startIcon={<ArrowBackIcon />}
				title={m.backToCart}
				variant="text"
				onClick={async () => await router.push({
					pathname: webshopCartOverviewPage?.uri as string,
					query: {
						cartId: router.query.cartId
					}
				})}
			/>
			<div className={bem('container')}>
				<div className={bem('items')}>
					<ShopCartCheckoutBillingOptionsCard billingOptions={billingOptions} setBillingOptions={setBillingOptions} />
					<ShopCartCheckoutTermsOfServicesCard setTermsOfServices={setTermsOfServices} termsOfServices={termsOfServices}/>
				</div>
				<div className={bem('summary')}>
					<ShopCartCheckoutSummary billingOptions={billingOptions} pages={pages} termsOfServices={termsOfServices} />
				</div>
			</div>
		</div>
	)
}