import React, { FC } from "react"

import { Card } from "src/components/common/card"

import { create } from "src/helpers/bem"
import { useFilter } from "src/states/shop/filter"
import { TranslationMessages } from "src/translations"

import styles from "./ShopOverviewInfo.module.scss"

const bem = create(styles, "ShopOverviewInfo")

export type ShopOverviewInfoProps = {
  messages: TranslationMessages
}

export const ShopOverviewInfo: FC<ShopOverviewInfoProps> = ({ messages }) => {
  const m = messages.pages.shop.overview.sidebar.info
  const { filterBy } = useFilter()
  const info = filterBy?.category?.info
  if (!info) {
    return null
  }
  return (
    <Card
      classNames={{
        root: bem(),
      }}
    >
      <div className={bem("headline")}>{m.title}</div>
      <p className={bem("content")}>{info}</p>
    </Card>
  )
}
