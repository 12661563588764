import React, { FC, useEffect, useState } from "react"

import { Tabs } from "src/components/common/tabs"
import { isAccountIncomplete, mergeAccount, useAccount } from "src/states/account"
import { useAccountFormWebshop } from "src/states/accountFormWebshop"
import { useLocale } from "src/states/locale"

import { fetchTranslationMessages, TranslationMessages } from "src/translations"

import { SharedPageProps } from "src/types/SharedPageProps"

import { AccountOverviewOrganization } from "./AccountOverviewOrganization"
import { AccountOverviewPrivate } from "./AccountOverviewPrivate"
import { AccountOverviewWebshop } from "./AccountOverviewWebshop"

export const AccountOverview: FC<SharedPageProps> = (props) => {
  const account = useAccount()
  const accountFormWebshop = useAccountFormWebshop()
  const formIncomplete = isAccountIncomplete()
  const accountAutorizationRequested = useAccountFormWebshop().isIncidentLoaded ?? false
  const [messages, setMessages] = useState<TranslationMessages | null>(null)
  const locale = useLocale()
  const m = messages?.pages.account.overview
  let tabItems =  [
    {
    label: m?.account.headline ?? "",
    node: <AccountOverviewPrivate {...props} messages={messages} />,
    },
    {
      label: m?.organization.headline ?? "",
      labelinfo: formIncomplete ? m?.organization.uncomplete : undefined,
      node: (
        <AccountOverviewOrganization {...props} messages={messages} />
      ),
    },
  ]

  tabItems = process.env.ENABLE_WEBSHOP === "true" ? [...tabItems, {
    label: m?.webshop.headline ?? "",
    labelinfo: accountAutorizationRequested ? undefined : m?.webshop.notAuthorized,
    node: <AccountOverviewWebshop {...props} messages={messages} />
  }] : tabItems

  useEffect(() => {
    async function fetchTranslations() {
      const messages = await fetchTranslationMessages(locale).then((el) => el)
      setMessages(messages)
    }
    fetchTranslations()
  }, [locale])

  useEffect(() => {
    // After navigating here from StandSelectionDialog where the user clicked the
    // authorize button, reset active tab again after successfully having focused
    // the webshop tab just for this occasion.
    mergeAccount({ webshop: { shouldActivateAccountWebshopTab: false } })
},[])

  return (
    <div>
      <Tabs
        id="account-overview"
        items={tabItems}
        value={account.webshop?.shouldActivateAccountWebshopTab ? 2 : formIncomplete ? 1 : 0}
      />
    </div>
  )
}
