import { createSimpleGlobalState } from "src/hooks/simpleGlobalState"

import { xRMApiProduct, xRMApiProductContent } from "src/types/xRM"

export type Product = xRMApiProduct

export type ProductContent = xRMApiProductContent

export type Products = {
  loading: boolean
  products: Product[]
  filteredProducts: Product[]
}

const { getValue, setValue, mergeValue, useValue } =
  createSimpleGlobalState<Products>(
    {
      loading: true,
      products: [],
      filteredProducts: []
    },
    "shop-products",
  )

export {
  getValue as getProducts,
  setValue as setProducts,
  mergeValue as mergeProducts,
  useValue as useProducts,
}
