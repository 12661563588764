import { useAccount, useMsal } from "@azure/msal-react"
import React, { FC } from "react"

import { msalConfig } from "src/auth/msalConfig"
import { Button } from "src/components/common/button"

import { create } from "src/helpers/bem"
import { useLocale } from "src/states/locale"

import { TranslationMessages } from "src/translations"
import { SharedPageProps } from "src/types/SharedPageProps"

import styles from "./AccountOverviewPrivate.module.scss"

const bem = create(styles, "AccountOverviewPrivate")

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>

export type AccountOverviewPrivateProps = Omit<SharedPageProps, "messages"> & {
  messages: TranslationMessages | null
}

export const AccountOverviewPrivate: FC<AccountOverviewPrivateProps> = ({
  messages,
  support,
}) => {
  const { instance, accounts } = useMsal()
  const account = useAccount(accounts[0])
  const m = messages?.pages.account.overview.account
  const locale = useLocale()

  const resetPassword = () =>
    (window.location.href = `https://${process.env.AAD_TENANT_NAME}.b2clogin.com/${process.env.AAD_TENANT_NAME}.onmicrosoft.com/oauth2/v2.0/authorize?p=${process.env.AAD_RESET_PASSWORD_FLOW_NAME}&client_id=${process.env.AAD_CLIENT_ID}&nonce=defaultNonce&redirect_uri=${window.location.origin}${msalConfig.auth.redirectUri}&scope=openid%20${process.env.AAD_SCOPE}&response_type=id_token%20token&prompt=login&lang=${locale}`)

  const renderAccountDataItem = (title: string, value?: string) => (
    <li className={bem("item")}>
      <span className={bem("item__title")}>{title}</span>
      <span>{value && value !== "unknown" ? value : m?.userInfo.noData}</span>
    </li>
  )

  return (
    <div className={bem()}>
      <p className={bem("content")}>{m?.desc}</p>
      <ul className={bem("items")}>
        {renderAccountDataItem(m?.userInfo.name ?? "", account?.name)}
        {renderAccountDataItem(
          m?.userInfo.email ?? "",
          account?.idTokenClaims?.email as string,
        )}
      </ul>
      <div className={bem("action")}>
        <p className={bem("action__desc")}>{m?.reset.desc}</p>
        <Button
          className={bem("action__button")}
          title={m?.reset.button}
          onClick={() => resetPassword()}
        />
      </div>
      {support.data.themeOptions?.support?.email?.account ? (
        <div className={bem("action")}>
          <p className={bem("action__desc")}>
            {m?.support.desc1}&nbsp;
            {support.data.themeOptions?.support?.email.account}
            {m?.support.desc2}
          </p>
          <Button
            className={bem("action__button")}
            title={m?.support.button}
            onClick={(e) => {
              window.location.href = `mailto:${support.data.themeOptions?.support?.email?.account}`
              e.preventDefault()
            }}
          />
        </div>
      ) : null}
    </div>
  )
}
