import { CardContent, List, ListItem, ListItemText, ListSubheader, Skeleton } from "@mui/material"
import { useRouter } from "next/router"
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react"

import { Card } from "src/components/common/card"
import { Checkbox } from "src/components/common/checkbox"
import { create } from "src/helpers/bem"

import { useTranslation } from "src/hooks/translation"


import { fetchBillingOptions } from "src/queriesXRM/purchaseorder"
import { useCart } from "src/states/shop/carts"
import { xRMApiCheckoutPermission } from "src/types/xRM"

import styles from "./ShopCartCheckoutBillingOptionsCard.module.scss"

const bem = create(styles, "ShopCartCheckoutBillingOptionsCard")

export type ShopCartCheckoutBillingOptionsCardProps = {
	billingOptions: BillingOptions
	setBillingOptions: Dispatch<SetStateAction<BillingOptions>>
}

export type BillingOptions = {
	orderOnOwnAccount: boolean
	permission: xRMApiCheckoutPermission
}

export const defaultBillingOptions: BillingOptions = {
	orderOnOwnAccount: false,
	permission: { checkoutPermission : "None" }
}

export const ShopCartCheckoutBillingOptionsCard: FC<ShopCartCheckoutBillingOptionsCardProps> = ({ billingOptions , setBillingOptions }) => {
	const translation = useTranslation()
	const router = useRouter()
	const cart = useCart({ id: router.query.cartId as string})
	const [ loading, setLoading ] = useState(false)
	const m = translation.messages.pages.shop.cart.checkout.billingCard

	useEffect(() => {
		setLoading(true)
		const fetchBillingOptionsAsync = async () => {
			const checkoutPermission = await fetchBillingOptions(cart.id)
			setBillingOptions({ orderOnOwnAccount: checkoutPermission.checkoutPermission === "BuyOnOwnAccount", permission: checkoutPermission })
	}
		fetchBillingOptionsAsync().finally(() => setLoading(false))
		console.log(billingOptions)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const canChooseBilling = billingOptions.permission.checkoutPermission === 'Both'

	const renderBillingListLoading = () =>
		<List subheader={<Skeleton height={40} width={100} />}>
			<ListItem sx={{ display: 'flex', gap: '15px'}}>
				<Skeleton height={40} width={"90%"} />
				<Skeleton height={40} width={"5%"} />
			</ListItem>
		</List>

	const renderBillingList = () =>
		<List
			className={bem('billinglist')}
			subheader={<ListSubheader>{m.debitorDesc}</ListSubheader>}
		>
			<ListItem
				disablePadding
				secondaryAction={
					<Checkbox
						disableRipple
						checked={billingOptions.orderOnOwnAccount}
						disabled={!canChooseBilling}
						edge="end"
						tabIndex={-1}
						onChange={(_, checked) => setBillingOptions({ ...billingOptions, orderOnOwnAccount: checked })}
					/>
				}
			>
				<ListItemText
					className={bem('label', { 'is-disabled': !canChooseBilling})}
					primary={m.debitorLabel}
				/>
			</ListItem>
		</List>

	return (
		<Card>
			<CardContent>
				<div className={bem()}>
					{ loading ? renderBillingListLoading() : renderBillingList() }
				</div>
			</CardContent>
		</Card>
	)
}