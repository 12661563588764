import { CardActions, CardContent, List, ListItem, ListItemButton, ListItemText, ListSubheader, Skeleton } from "@mui/material"
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react"

import { Button } from "src/components/common/button"
import { Card } from "src/components/common/card"
import { Checkbox } from "src/components/common/checkbox"
import { Link } from "src/components/common/link"
import { create } from "src/helpers/bem"

import { useTranslation } from "src/hooks/translation"

import { fetchToS } from "src/queriesXRM/tos"
import { useLocale } from "src/states/locale"
import { xRMApiTermsOfService } from "src/types/xRM"

import styles from "./ShopCartCheckoutTermsOfServicesCard.module.scss"

const bem = create(styles, "ShopCartCheckoutTermsOfServicesCard")

export type ShopCartCheckoutTermsOfServicesCardProps = {
	termsOfServices: xRMApiTermsOfService[]
	setTermsOfServices: Dispatch<SetStateAction<xRMApiTermsOfService[]>>
}

export const ShopCartCheckoutTermsOfServicesCard: FC<ShopCartCheckoutTermsOfServicesCardProps> = ({ termsOfServices, setTermsOfServices }) => {
	const translation = useTranslation()
	const locale = useLocale()
	const [ loading, setLoading ] = useState(false)
	const generalTermsAndConditions = termsOfServices.filter(tos => tos.termsOfService?.urlType === "GeneralTermsAndConditions")
	const privacyPolicies = termsOfServices.filter(tos => tos.termsOfService?.urlType === "PrivacyPolicy")
	const m = translation.messages.pages.shop.cart.checkout.tosCard

	useEffect(() => {
		setLoading(true)
		const fetchToSAsync = async () => setTermsOfServices(await fetchToS())
		fetchToSAsync().finally(() => setLoading(false))
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [locale])

	const renderTermsOfServicesListLoading = () =>
		<List subheader={<Skeleton height={40} width={100} />}>
			<ListItem sx={{ display: 'flex', gap: '15px'}}>
				<Skeleton height={40} width={"90%"} />
				<Skeleton height={40} width={"5%"} />
			</ListItem>
		</List>

	const renderTermsOfServicesList = (name: string, toS: xRMApiTermsOfService[]) =>
		<List
			className={bem('toslist')}
			subheader={toS.length > 0 ? <ListSubheader>{name}</ListSubheader> : ""}
		>
			{toS.map(t =>
				<ListItem
					key={t.termsOfService?.id}
					disablePadding
					secondaryAction={
						<Checkbox
							disableRipple
							checked={termsOfServices.find(tt => tt.termsOfService?.id === t.termsOfService?.id)?.value}
							edge="end"
							tabIndex={-1}
							onChange={(_, checked) => setTermsOfServices(
								termsOfServices.map(tt => tt.termsOfService?.id === t.termsOfService?.id
									? { ...tt, value: checked }
									: tt))
							}
						/>
					}
				>
					<ListItemButton
						dense
						href={`${t.termsOfService!.url!.startsWith('http') ? "" : "http://"}${t.termsOfService!.url!}`}
						LinkComponent={Link}
						target="_blank"
					>
						<ListItemText className={bem('toslink')} primary={t.termsOfService?.name} />
					</ListItemButton>
				</ListItem>)}
		</List>

	return (termsOfServices.length > 0 ?
		<Card>
			<CardContent>
				<div className={bem()}>
					{ loading ? renderTermsOfServicesListLoading() : renderTermsOfServicesList(m.generalTermsAndConditions, generalTermsAndConditions) }
					{ loading ? renderTermsOfServicesListLoading() : renderTermsOfServicesList(m.privacyPolicies, privacyPolicies) }
				</div>
			</CardContent>
			<CardActions className={bem('actions')}>
				<div>
					{loading
						? <Skeleton height={40} width={100} />
						: <Button
							title={m.acceptAll}
							variant="text"
							onClick={() => setTermsOfServices(termsOfServices.map(t => ({ ...t, value: true })))}
						/>}
				</div>
			</CardActions>
		</Card> : <></>
	)
}