import { Pagination } from "@mui/material"
import React, { FC, useEffect, useMemo, useState } from "react"

import { ProductCard } from "src/components/common/productCard"

import { create } from "src/helpers/bem"

import { useProducts } from "src/states/shop/products"

import { TranslationMessages } from "src/translations"

import styles from "./ShopOverviewProducts.module.scss"

const bem = create(styles, "ShopOverviewProducts")

export type ShopOverviewProductsProps = {
  messages: TranslationMessages
}

export const ShopOverviewProducts: FC<ShopOverviewProductsProps> = ({
  messages
}) => {
  const m = messages.pages.shop.overview.products
  const { filteredProducts } = useProducts()
  const [page, setPage] = useState(1)
  const productsPerPage = 25
  const pageCount = Math.ceil(filteredProducts.length / productsPerPage)
  const pagedProducts = useMemo(() => {
    return filteredProducts.slice((page - 1) * productsPerPage, (page - 1) * productsPerPage + productsPerPage)
  }, [filteredProducts, page])

  useEffect(() => {
    if (pageCount < page) {
      setPage(1)
    }
  }, [pageCount, page])

  return (
    <div className={bem()}>
      { pageCount > 1 &&
        <div className={bem("pagination")}>
          <Pagination
            count={pageCount}
            page={page}
            onChange={(_, value) => setPage(value)} />
        </div>
      }
      <div className={bem("products")}>
        {pagedProducts && pagedProducts.map((product, i) => (
          <ProductCard
            key={i}
            className={bem("products__product")}
            pricePrefix={product?.hasVariants ? m.prefix : ''}
            {...product}
          />
        ))}
      </div>
    </div>
  )
}
