import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import NoPhotographyIcon from "@mui/icons-material/NoPhotography"
import StarIcon from "@mui/icons-material/Star"
import React, { FC, ReactNode } from "react"

import { BaseLink } from "src/components/common/base-link"
import { Card } from "src/components/common/card"
import { LazyImage } from "src/components/common/lazyImage"

import { create } from "src/helpers/bem"

import { formatPrice } from "src/helpers/price"

import { useTranslation } from "src/hooks/translation"
import { useLocale } from "src/states/locale"
import { Product } from "src/states/shop/products"

import styles from "./ProductCard.module.scss"

const bem = create(styles, "ProductCard")

export type ProductCardImageProps = {
  src: string
  alt: string
}

export type ProductCardProps = Omit<
  Product,
  "contents" | "productAttributesForm" | "productAttributeDependencies"
> & {
  className?: string
  pricePrefix?: string
}

export const ProductCard: FC<ProductCardProps> = ({
  className,
  id,
  image,
  tag,
  favorite,
  name,
  note,
  pricePrefix,
  price,
  unit,
}) => {
  const locale = useLocale()
  const { messages } = useTranslation()

  const renderLink = (children: ReactNode, className?: string) => {
    return (
      <BaseLink
        className={className && bem(className)}
        href={`/${locale}/${messages.pages.shop.overview.products.route}?id=${id}`}
      >
        {children}
      </BaseLink>
    )
  }

  return (
    <Card
      classNames={{
        root: bem(undefined, undefined, className),
        content: bem("wrapper"),
      }}
    >
      {image && tag ? <span className={bem("header__tag")}>{tag}</span> : null}
      {image && favorite ? <StarIcon className={bem("header__icon")} /> : null}
      {renderLink(
        <div className={bem("image")}>
          {image?.src ? (
            <div>
              <LazyImage
                alt={image?.alt ?? ""}
                className={bem("image__lazy", "has-link: true")}
                src={image.src}
              />
            </div>
          ) : (
            <NoPhotographyIcon
              className={bem("image__placeholder", "has-link: true")}
              color="disabled"
            />
          )}
        </div>,
      )}
      <div className={bem("content")}>
        {renderLink(
          <h6 className={bem("title", "has-link: true")}>{name}</h6>,
          "title__link",
        )}
        {note && <p className={bem("note")}>{note}</p>}
        <div className={bem("footer")}>
          {price != null ? (
            <p className={bem("price__wrapper")}>
              {pricePrefix}&nbsp;
              <span className={bem("price")}>{formatPrice(price, locale)}</span>
              {unit && <span className={bem("unit")}>&nbsp;/&nbsp;{unit}</span>}
            </p>
          ) : (
            <span className={bem("unit")}>
              {messages.pages.shop.overview.noPrice}
            </span>
          )}
          {renderLink(
            <button className={bem("button")}>
              <ArrowForwardIcon color="primary" />
            </button>,
          )}
        </div>
      </div>
    </Card>
  )
}
