import { ImmutableObject } from "@hookstate/core"
import React, { FC } from "react"

import { create } from "src/helpers/bem"

import { Product } from "src/states/shop/products"
import { ProductVariant } from "src/states/shop/productVariants"
import { TranslationMessages } from "src/translations"

import { PagesProps } from "src/types/SharedPageProps"

import styles from "./ShopDetailHeader.module.scss"
import { ShopDetailHeaderContent } from "./ShopDetailHeaderContent"
import { ShopDetailHeaderImages } from "./ShopDetailHeaderImages"
import { ShopDetailHeaderNavigation } from "./ShopDetailHeaderNavigation"

const bem = create(styles, "ShopDetailHeader")

export type ShopDetailHeaderProps = {
  product: ImmutableObject<Product>
  variant: ImmutableObject<ProductVariant>
  setVariant: React.Dispatch<React.SetStateAction<ImmutableObject<ProductVariant>>>
  pages: PagesProps
  messages: TranslationMessages
}

export const ShopDetailHeader: FC<ShopDetailHeaderProps> = ({ product, variant, setVariant, pages, messages }) => {

  return (
    <div className={bem()}>
      <ShopDetailHeaderNavigation messages={messages} pages={pages} />
      <div className={bem("header")}>
        <ShopDetailHeaderImages productVariant={variant} />
        <ShopDetailHeaderContent
          messages={messages}
          product={product}
          productVariant={variant}
          setProductVariant={setVariant} />
      </div>
    </div>
  )
}
