import xrmApi from "src/services/xrmApi"

import { xRMApiInvitation } from "src/types/xRM"

export const getInvitationAddressBySalesorderId = async (id: string) => {
  return xrmApi
    .get(`/invitation/address?salesorderId=${id}`)
    .then(async (res) => {
      if (res?.data) {
        return res.data
      }
      return null
    })
}

export const getInvitationActiveBySalesorderId = async (id: string) => {
  return xrmApi
    .get(`/invitation/active?salesorderId=${id}`)
    .then(async (res) => {
      if (res?.status === 200) {
        return true
      }
      return false
    })
}

export const postInvitation = async (data: xRMApiInvitation) => {
  return xrmApi.post("/invitation", data).then(async (res) => {
    if (res?.data) {
      return res.data
    }
    return null
  })
}
