import DOMPurify from "dompurify"
import React, { FC } from "react"

import { Card } from "src/components/common/card"

import { create } from "src/helpers/bem"
import { useFilter } from "src/states/shop/filter"
import { TranslationMessages } from "src/translations"

import styles from "./ShopOverviewContact.module.scss"

const bem = create(styles, "ShopOverviewContact")

export type ShopOverviewContactProps = {
  messages: TranslationMessages
}

export const ShopOverviewContact: FC<ShopOverviewContactProps> = ({
  messages,
}) => {
  const m = messages.pages.shop.overview.sidebar.contact
  const { filterBy } = useFilter()
  const contact = filterBy?.category?.contactString
  if (!contact) {
    return null
  }
  return (
    <Card
      classNames={{root: bem(),}}>
      <div className={bem("headline")}>{m.title}</div>
      {contact && (
            <p
              className={bem("content")}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(contact),
              }}
            />
          )}
    </Card>
  )
}
