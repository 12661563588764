import { ImmutableObject } from "@hookstate/core"
import React, { FC } from "react"

import { Gallery, GalleryItemProp } from "src/components/common/gallery"

import { create } from "src/helpers/bem"
import { ProductVariant } from "src/states/shop/productVariants"

import styles from "./ShopDetailHeaderImages.module.scss"

const bem = create(styles, "ShopDetailHeaderImages")

export type ShopDetailHeaderImagesProps = {
  productVariant: ImmutableObject<ProductVariant>
}

export const ShopDetailHeaderImages: FC<ShopDetailHeaderImagesProps> = ({ productVariant }) => {
  return (
    <div className={bem()}>
      <Gallery items={(productVariant?.images || []) as GalleryItemProp[]} />
    </div>
  )
}
