import { useIsAuthenticated } from '@azure/msal-react';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UpdateIcon from '@mui/icons-material/Update';
import { Alert, LinearProgress } from "@mui/material"

import React, { FC, useEffect, useState } from "react"
import { AdvancedForm, AdvancedFormFieldProps, AdvancedForms, FieldValue } from "src/components/common/advancedForm"
import { Button } from "src/components/common/button"
import { create } from "src/helpers/bem"

import { deepClone } from 'src/helpers/deepClone';
import { getFilesById } from 'src/queriesXRM/file';
import { fetchIncidentAuthorization, postIncidentAuthorization } from "src/queriesXRM/incident"

import { useAccountForm } from 'src/states/accountForm';
import { mergeAccountFormWebshop, mergeDependencyForCountry, resetAccountFormWebshop, useAccountFormWebshop } from "src/states/accountFormWebshop"
import { useCountries } from 'src/states/countries';
import { TranslationMessages } from "src/translations"
import { SharedPageProps } from "src/types/SharedPageProps"

import { xRMApiAccount, xRMApiFile, xRMApiIncident } from 'src/types/xRM';

import styles from "./AccountOverviewWebshop.module.scss"

const bem = create(styles, "AccountOverviewWebshop")

export type AccountOverviewWebshopProps = Omit<SharedPageProps, "messages"> & {
  messages: TranslationMessages | null
}

export const AccountOverviewWebshop: FC<AccountOverviewWebshopProps> = ({ messages }) => {
  const incidentType = "AuthorizationRequest"
  const m = messages?.pages.account.overview.webshop
  const currentIncident = useAccountFormWebshop()
  const accountForm = useAccountForm()
  const isAuthenticated = useIsAuthenticated()
  const countries = useCountries()
  const [loading, setLoading] = useState(false)

  const formDisabled = currentIncident.isIncidentLoaded ?? false
  const form = currentIncident.forms
  const accountFields = accountForm.flatMap(f => f.fields) as AdvancedFormFieldProps[]
  const formattedAccountFields = deepClone<AdvancedFormFieldProps[]>(accountFields).reduce((obj, item) => Object.assign(obj, { [item.name ?? ""]: item.value }),{},) as xRMApiAccount
  const countryIdDE = countries.find(country => country.countryTag === "DEU")?.key
  const fields = form.flatMap(f => f.fields).filter(f => f.conditions?.display !== false) as AdvancedFormFieldProps[]

  useEffect(() => {
    setLoading(true)
		if(isAuthenticated)
      {
        fetchIncidentAuthorization().finally(async () => {
          mergeDependencyForCountry({
            value: countryIdDE as unknown as Record<string, unknown>,
          })
          const defaultFiles = await getFilesById("Webshop", currentIncident.incidentId) as xRMApiFile[]
          if(formDisabled && defaultFiles) {
              const formattedFiles = defaultFiles.map((field) => (
                {
                  name: field.name?.split("/")[1],
                  size: field.size
                }
              ))
              mergeAccountFormWebshop(1, {value: formattedFiles as FieldValue }, "businessCertificate")
            }
          setLoading(false)
        })
      }
  }, [])

  const onSendForm = async () => {
    setLoading(true)
    const formattedWebshopFields = deepClone<AdvancedFormFieldProps[]>(fields).reduce((obj, item) => Object.assign(obj, { [item.name ?? ""]: item.value }),{},) as xRMApiIncident
    formattedWebshopFields.exhibitionId = process.env.NEXT_PUBLIC_XRM_EVENT_ID
    formattedWebshopFields.incidentType = incidentType
    formattedAccountFields.authorizationIncident = formattedWebshopFields
    formattedAccountFields.authorizationIncident.id = currentIncident.incidentId
    await postIncidentAuthorization(formattedAccountFields).finally(() => setLoading(false))
  }

  if (loading) {
    return <LinearProgress color="primary" />
  }
  const requiredFields = fields
    .filter((el) => el.required)
    .map((el) => el["value"])
  const errors = fields.filter((el) => el?.errorText)

  const infoCompletedState = (
    <div className={bem("state__text")}>
      <CheckCircleIcon className={bem("state__text__icon__completed")}/>
      {m?.webshopTextCompleted1} {m?.webshopLink} {m?.webshopTextCompleted2}
    </div>)

  const infoPendingState = (
    <div className={bem("state__text")}>
      <UpdateIcon className={bem("state__text__icon__waiting")}/>
      {m?.webshopTextPending}
    </div>)

  const infoCancelledState = (
  <div className={bem("state__text")}>
    <CancelIcon className={bem("state__text__icon__cancelled")} />
    {m?.webshopTextCancelled}
  </div>)
  const autorisationCompleted = currentIncident.statecode === "Closed"
  const hasBeenRequested = currentIncident.statecode === "Active"
  const hasBeenCancelled = currentIncident.statecode === "Cancelled"

  const stateInfo = autorisationCompleted ? infoCompletedState : hasBeenRequested ? infoPendingState : hasBeenCancelled ? infoCancelledState : ""
  return (
    <div className={bem()}>
      <Alert className={bem("info")} severity="info">
          <p className={bem("info__text")}>{m?.info}</p>
      </Alert>
      <AdvancedForm
        data={form as readonly AdvancedForms[]}
        formDisabled={formDisabled}
        formMessages={m?.form}
        handleMergeField={mergeAccountFormWebshop}
        handleReset={resetAccountFormWebshop}
        id={currentIncident.incidentId}
        messages={messages ?? undefined}
      />
      <Button
        className={bem("button")}
        title={m?.button}
        disabled={
          formDisabled ||
          errors.length > 0 ||
          requiredFields.includes(undefined) ||
          requiredFields.includes("")
        }
        onClick={onSendForm}
      />
      <div className={bem("state")}>
        {stateInfo}
      </div>
    </div>
  )
}
