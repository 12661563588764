import { useAccount as useAccountMsal, useMsal } from "@azure/msal-react"
import LinearProgress from "@mui/material/LinearProgress"
import React, { FC, useEffect, useState } from "react"

import {
  AdvancedForm,
  AdvancedForms,
  AdvancedFormFieldProps,
} from "src/components/common/advancedForm"
import { Button } from "src/components/common/button"

import { create } from "src/helpers/bem"
import { postAccount } from "src/queriesXRM/account"

import { isAccountIncomplete, useAccount } from "src/states/account"
import {
  useAccountForm,
  mergeAccountForm,
  resetAccountForm,
} from "src/states/accountForm"
import { TranslationMessages } from "src/translations"

import { SharedPageProps } from "src/types/SharedPageProps"

import { xRMApiAccount } from "src/types/xRM"

import styles from "./AccountOverviewOrganization.module.scss"

const bem = create(styles, "AccountOverviewOrganization")

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>

export type AccountOverviewOrganizationProps = Omit<
  SharedPageProps,
  "messages"
> & {
  messages: TranslationMessages | null
}

export const AccountOverviewOrganization: FC<
  AccountOverviewOrganizationProps
> = ({ messages }) => {
  const m = messages?.pages.account.overview.organization
  const [loading, setLoading] = useState(false)
  const { accounts } = useMsal()
  const account = useAccount()
  const formDisabled = !isAccountIncomplete()
  const accountMsal = useAccountMsal(accounts[0])
  const organization = useAccountForm()
  const mergedOrganization = [].concat.apply(
    [],
    Object.assign(organization.map((el) => el.fields)),
  ) as AdvancedFormFieldProps[]

  const setAccountData = () => {
    mergeAccountForm(
      0,
      {
        value: accountMsal?.idTokenClaims?.given_name as string,
        disabled: true,
      },
      "firstName",
    )
    mergeAccountForm(
      0,
      {
        value: accountMsal?.idTokenClaims?.family_name as string,
        disabled: true,
      },
      "lastName",
    )
    mergeAccountForm(
      0,
      {
        value: (accountMsal?.idTokenClaims?.email as string) ?? "",
        disabled: true,
      },
      "email",
    )
  }

  useEffect(() => {
    setAccountData()
  }, [accountMsal])

  const onSendForm = async () => {
    setLoading(true)
    const formatedOrganization = mergedOrganization?.reduce(
      (obj, item) => Object.assign(obj, { [item.name ?? ""]: item.value }),
      {},
    ) as xRMApiAccount
    return postAccount(formatedOrganization)
      .then(() => setLoading(false))
      .catch(() => setLoading(false))
  }

  if (loading) {
    return <LinearProgress color="primary" />
  }
  const requiredFields = mergedOrganization
    .filter((el) => el.required)
    .map((el) => el["value"])
  const errors = mergedOrganization.filter((el) => el?.errorText)
  return (
    <div className={bem()}>
      <AdvancedForm
        data={organization as readonly AdvancedForms[]}
        formDisabled={formDisabled}
        formMessages={m?.form}
        handleMergeField={mergeAccountForm}
        handleReset={resetAccountForm}
        messages={messages ?? undefined}
      />
      <Button
        className={bem("button")}
        title={m?.button}
        disabled={
          formDisabled ||
          errors.length > 0 ||
          requiredFields.includes(undefined) ||
          requiredFields.includes("")
        }
        onClick={onSendForm}
      />
    </div>
  )
}
