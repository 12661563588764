import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { LinearProgress } from '@mui/material';

import { useRouter } from 'next/router';

import { FC, useEffect, useMemo, useState } from "react"

import { Button } from "src/components/common/button"
import { ShopCartOverviewSummary } from 'src/components/services/shop/cart/overview/ShopCartOverviewSummary';
import { ShopCartProductCard } from 'src/components/services/shop/cart/ShopCartProductCard';
import { create } from 'src/helpers/bem';
import { getPage } from 'src/helpers/pages';
import { useLocale } from 'src/states/locale';
import { postRefreshCart, setCart, useCart } from 'src/states/shop/carts';
import { TranslationMessages } from "src/translations"

import { PagesProps } from 'src/types/SharedPageProps';

import styles from "./ShopCartOverview.module.scss"

const bem = create(styles, "ShopCartOverview")

export type ShopCartOverviewProps = {
	messages: TranslationMessages,
	pages: PagesProps
}

export const ShopCartOverview: FC<ShopCartOverviewProps> = ({ messages, pages }) => {
	const locale = useLocale()
	const router = useRouter()
	const cart = useCart({ id: router.query.cartId as string})
	const m = messages.pages.shop.cart.overview
	const [ loading, setLoading ] = useState(false)

	useEffect(() => {
		setLoading(true)
		const refreshCartAsync = async () => setCart(await postRefreshCart(cart))
		refreshCartAsync().finally(() => setLoading(false))
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [locale])

	const webshopOverviewPage = useMemo(() => getPage("Webshop Overview Template", locale, pages),[pages, locale])

	return (
		<div className={bem()}>
			<Button
				startIcon={<ArrowBackIcon />}
				title={m.backToShop}
				variant="text"
				onClick={async () => await router.push({
					pathname: webshopOverviewPage?.uri as string,
					query: {
						cartId: router.query.cartId
					}
				})}
			/>
			<div className={bem('container')}>
				<div className={bem('items')}>
					{loading ? <LinearProgress color="primary" />
						: cart.products && cart.products?.length > 0
							? cart.products.map(product =>
								<ShopCartProductCard
									key={product.productVariant?.id}
									item={product}
								/>)
							: <div className={bem('noData')}>{m.noData}</div>}
				</div>
				<div className={bem('summary')}>
					<ShopCartOverviewSummary loadingProducts={loading} pages={pages} products={cart.products}/>
				</div>
			</div>
		</div>
	)
}
