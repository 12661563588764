import { CardContent } from "@mui/material"
import moment from "moment"
import { FC } from "react"
import { Card } from "src/components/common/card"
import { create } from "src/helpers/bem"

import { formatPrice } from "src/helpers/price"
import { useTranslation } from "src/hooks/translation"
import { useBookings } from "src/states/bookings"
import { useLocale } from "src/states/locale"
import { xRMApiPurchaseorder } from "src/types/xRM"

import styles from "./ShopOrderDetailsSummary.module.scss"

const bem = create(styles, "ShopOrderDetailsSummary")

export type ShopOrderDetailsSummaryProps = {
	order: xRMApiPurchaseorder
}

export const ShopOrderDetailsSummary: FC<ShopOrderDetailsSummaryProps> = ({ order }) => {
	const locale = useLocale()
	const translation = useTranslation()
	const { bookings } = useBookings()
	const m = translation.messages.pages.shop.orders.details.summary

	return (
		<Card>
			<CardContent>
				<div className={bem()}>
					<div className={bem('summary')}>
						<div className={bem('summary__line')}>
							<div className={bem('summary__line__block')}>
								<div>{m.exhibition}</div>
								<div>{order.exhibition?.name}</div>
							</div>
							<div className={bem('summary__line__block')}>
								<div>{m.booking}</div>
								<div>{bookings.find(booking => booking.id === order.parentSalesorderId!)?.name!}</div>
							</div>
							<div className={bem('summary__line__block')}>
								<div>{m.standhall}</div>
								<div>{bookings.find(booking => booking.id === order.parentSalesorderId!)?.standhall ?? "-"}</div>
							</div>
							<div className={bem('summary__line__block')}>
								<div>{m.standnumber}</div>
								<div>{bookings.find(booking => booking.id === order.parentSalesorderId!)?.standnumber ?? "-"}</div>
							</div>
						</div>
						<div className={bem('summary__line')}>
						<div className={bem('summary__line__block')}>
								<div>{m.orderNumber}</div>
								<div>{order.orderNumber}</div>
							</div>
							<div className={bem('summary__line__block')}>
								<div>{m.orderDate}</div>
								<div>{moment(order.registrationDate!).format("lll")}</div>
							</div>
							<div className={bem('summary__line__block')}>
								<div>{m.status.label}</div>
								<div>{m.status.items[order.phase!]}</div>
							</div>
							<div className={bem('summary__line__block')}>
								<div>{m.total.label}</div>
								<div>{order.totalAmount !== 0 ? formatPrice(order.totalAmount, locale) : m.total.noAmount}</div>
							</div>
						</div>
					</div>
				</div>
			</CardContent>
		</Card>
	)
}