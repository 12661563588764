import { ImmutableObject } from "@hookstate/core"
import React, { FC } from "react"

import { Tabs, TabItem } from "src/components/common/tabs"

import { create } from "src/helpers/bem"

import { Product } from "src/states/shop/products"

import { ProductVariant } from "src/states/shop/productVariants"

import styles from "./ShopDetailContent.module.scss"

const bem = create(styles, "ShopDetailContent")

export type ShopDetailContentProps = {
  product: ImmutableObject<Product>
  variant: ImmutableObject<ProductVariant>
}

export const ShopDetailContent: FC<ShopDetailContentProps> = ({ product, variant }) => {
  const contents = variant.contents
    ?.map(c => c.node ? c : product.contents?.find(cc => cc.label === c.label))
    ?.filter(c => c?.node)

  return (
    <div className={bem()}>
      <Tabs
        className={bem("tabs")}
        id="product-description-tabs"
        items={contents as TabItem[]}
      />
    </div>
  )
}
