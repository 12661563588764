import React, { FC, Fragment } from "react"

import { Link } from "src/components/common/link"
import { create } from "src/helpers/bem"
import { GET_PAGE_page_meta_breadcrumb } from "src/types/wordpress/generated/GET_PAGE"

import styles from "./Breadcrumb.module.scss"

const bem = create(styles, "Breadcrumb")

export type BreadcrumbProps = {
  disabled?: boolean
  items?: (GET_PAGE_page_meta_breadcrumb | null)[] | null
}

export const Breadcrumb: FC<BreadcrumbProps> = ({ disabled, items }) => {
  if (!items || items?.length <= 1 || disabled) {
    return null
  }
  return (
    <div className={bem()}>
      {items.map((item, i) => {
        const isFirst = i === 0
        const modified = {
          "is-active": i === items.length - 1,
        }
        return (
          <Fragment key={item?.title}>
            {!isFirst ? <span className={bem("seperator")}>{">"}</span> : null}
            {item?.path ? (
              <Link
                href={item?.path ?? ""}
                classNames={{
                  link: bem("link"),
                  label: bem("title", modified),
                }}
              >
                {item?.title}
              </Link>
            ) : (
              <span className={bem("title", modified)}>{item?.title}</span>
            )}
          </Fragment>
        )
      })}
    </div>
  )
}
