import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import InfoIcon from "@mui/icons-material/Info"
import {
  CardActions,
  CardContent,
  CircularProgress,
  Skeleton,
} from "@mui/material"
import { useRouter } from "next/router"

import { FC, useEffect, useMemo, useState } from "react"

import { Button } from "src/components/common/button"
import { Card } from "src/components/common/card"
import { create } from "src/helpers/bem"
import { deepClone } from "src/helpers/deepClone"
import { getPage } from "src/helpers/pages"
import { formatPrice } from "src/helpers/price"
import { useTranslation } from "src/hooks/translation"
import {
  postPurchaseorder,
  postPurchaseorderRefresh,
} from "src/queriesXRM/purchaseorder"
import { useBookings } from "src/states/bookings"
import { useLocale } from "src/states/locale"
import {
  CartProduct,
  getCartProductAmount,
  postRefreshCart,
  resetCart,
  setCart,
  useCart,
} from "src/states/shop/carts"

import { PagesProps } from "src/types/SharedPageProps"
import { xRMApiPurchaseorder, xRMApiTermsOfService } from "src/types/xRM"

import { BillingOptions } from "./ShopCartCheckoutBillingOptionsCard"
import styles from "./ShopCartCheckoutSummary.module.scss"

const bem = create(styles, "ShopCartCheckoutSummary")

export type ShopCartCheckoutSummaryProps = {
  billingOptions: BillingOptions
  pages: PagesProps
  termsOfServices: xRMApiTermsOfService[]
}

export const ShopCartCheckoutSummary: FC<ShopCartCheckoutSummaryProps> = ({
  billingOptions,
  pages,
  termsOfServices,
}) => {
  const locale = useLocale()
  const router = useRouter()
  const translation = useTranslation()
  const cart = useCart({ id: router.query.cartId as string })
  const { bookings } = useBookings()
  const [loading, setLoading] = useState(false)
  const [checkoutLoading, setCheckoutLoading] = useState(false)
  const [termsOfServicesAccepted, setTermsOfServicesAccepted] = useState(false)
  const m = translation.messages.pages.shop.cart.checkout.summary

  useEffect(() => {
    setTermsOfServicesAccepted(termsOfServices.every((t) => t.value))
  }, [termsOfServices])

  useEffect(() => {
    setLoading(true)
    setTermsOfServicesAccepted(false)
    const refreshCartAsync = async () => setCart(await postRefreshCart(cart))
    refreshCartAsync().finally(() => setLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const checkout = async () => {
    const purchaseorder: xRMApiPurchaseorder = {
      parentSalesorderId: cart.id,
      id: cart.orderId,
      exhibition: {
        id: process.env.NEXT_PUBLIC_XRM_EVENT_ID,
      },
      products: deepClone<CartProduct[]>(cart.products),
      agreements: termsOfServices,
      isPurchaseorderOnOwnAccount: billingOptions.orderOnOwnAccount,
    }
    const { mustRefresh } = await postPurchaseorder(purchaseorder)
    if (mustRefresh) {
      const refreshed = await postPurchaseorderRefresh(purchaseorder)
      setCart(refreshed.products ?? [])
    } else {
      resetCart()
    }
  }

  const webshopOrdersPage = useMemo(
    () => getPage("Webshop Orders Template", locale, pages),
    [pages, locale],
  )

  const bookingNumber = bookings.find((booking) => booking.id === cart.id)?.name

  const subtotal = useMemo(
    () =>
      formatPrice(
        cart.products.reduce(
          (sum, product) =>
            sum +
            product.productVariant?.price! * getCartProductAmount(product),
          0,
        ),
        locale,
      ),
    [cart, locale],
  )

  const total = useMemo(
    () =>
      formatPrice(
        cart.products.reduce(
          (sum, product) =>
            sum +
            product.productVariant?.price! * getCartProductAmount(product),
          0,
        ),
        locale,
      ),
    [cart, locale],
  )

  const cartEmpty = cart.products.length < 1
  const hasBillingAddress = billingOptions.permission.debitorName != null || billingOptions.permission.debitorForeignName != null
  const disabledBillingAdress = billingOptions.orderOnOwnAccount ? billingOptions.permission.debitorName == null : billingOptions.permission.debitorForeignName == null

  return (
    <Card>
      <CardContent>
        <div className={bem()}>
          <div className={bem("booking")}>
            <div>{m.booking}</div>
            <div>{bookingNumber}</div>
          </div>
          <div className={bem("total")}>
            <div>{m.total}</div>
            <div className={bem("total__result")}>
              {loading ? <Skeleton height={30} width={100} /> : total}
            </div>
          </div>
          <div
            className={bem("condition", { "is-disabled": disabledBillingAdress })}
          >
            {hasBillingAddress ? (
              !billingOptions.orderOnOwnAccount && billingOptions.permission.debitorForeignName != null ? (
                <>
                  <CheckCircleIcon />
                  <div>{m.conditions.billing}{billingOptions.permission.debitorForeignName}</div>
                </>
              ) : !billingOptions.orderOnOwnAccount && billingOptions.permission.debitorForeignName == null ? (
                <>
                  <InfoIcon />
                  <div>{m.conditions.noBilling.foreign}</div>
                </>
              ) : billingOptions.orderOnOwnAccount && billingOptions.permission.debitorName != null ? (
                <>
                  <CheckCircleIcon />
                  <div>{m.conditions.billing}{billingOptions.permission.debitorName}</div>
                </>
              ) : (
                <>
                  <InfoIcon />
                  <div>{m.conditions.noBilling.own}</div>
                </>
              )
            ) : ("")}
          </div>
          {termsOfServices.length > 0 ? (
            <div
              className={bem("condition", {
                "is-disabled": !termsOfServicesAccepted,
              })}
            >
              {termsOfServicesAccepted ? <CheckCircleIcon /> : <InfoIcon />}
              <div>
                {termsOfServicesAccepted
                  ? m.conditions.termsOfServices.met
                  : m.conditions.termsOfServices.unmet}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </CardContent>
      <CardActions className={bem("actions")}>
        {loading ? (
          <Skeleton height={40} width={"100%"} />
        ) : checkoutLoading ? (
          <CircularProgress />
        ) : (
          <Button
            title={m.checkout}
            variant="contained"
            disabled={
              cartEmpty || !termsOfServicesAccepted || disabledBillingAdress
            }
            onClick={async () => {
              try {
                setCheckoutLoading(true)
                await checkout()
                await router.push(webshopOrdersPage?.uri as string)
              } finally {
                setCheckoutLoading(false)
              }
            }}
          />
        )}
      </CardActions>
    </Card>
  )
}
