import React, { FC } from "react"

import { Accordion } from "src/components/common/accordion"
import { DataTable, DataTableItems } from "src/components/common/dataTable"

import { useCoExhibitorSalesorder } from "src/states/coExhibitorSalesorder"

import { TranslationMessages } from "src/translations"

export type CoExhibitorBookingDetailProps = {
  messages: TranslationMessages
}

export const CoExhibitorBookingDetail: FC<CoExhibitorBookingDetailProps> = ({
  messages,
}) => {
  const m = messages.pages.standBooking
  const { steps } = useCoExhibitorSalesorder()
  return (
    <div>
      {steps?.length > 0 ? (
        <div>
          {steps?.map((step, a) => {
            const formatedData = step?.forms?.map((el) => ({
              ...el,
              items: el.fields,
            }))
            return (
              <Accordion
                key={step.id}
                defaultExpanded={a === 0 ? true : false}
                id={step.id ?? ""}
                label={step?.label ?? (m as any)[step?.id ?? ""]?.title ?? ""}
              >
                <DataTable
                  data={formatedData as DataTableItems[]}
                  translations={(m as any)[step?.id ?? ""]}
                />
              </Accordion>
            )
          })}
        </div>
      ) : null}
    </div>
  )
}
