import { ImmutableArray } from "@hookstate/core"
import { CardActions, CardContent, Skeleton } from "@mui/material"
import { useRouter } from "next/router"

import { FC, useMemo } from "react"

import { Button } from "src/components/common/button"
import { Card } from "src/components/common/card"
import { create } from "src/helpers/bem"
import { getPage } from "src/helpers/pages"
import { formatPrice } from "src/helpers/price"
import { useTranslation } from "src/hooks/translation"
import { useBookings } from "src/states/bookings"
import { useLocale } from "src/states/locale"
import { CartProduct, getCartProductAmount, useCart } from "src/states/shop/carts"

import { PagesProps } from "src/types/SharedPageProps"

import styles from "./ShopCartOverviewSummary.module.scss"

const bem = create(styles, "ShopCartOverviewSummary")

export type ShopCartOverviewSummaryProps = {
	loadingProducts: boolean
	pages: PagesProps
	products: ImmutableArray<CartProduct>
}

export const ShopCartOverviewSummary: FC<ShopCartOverviewSummaryProps> = ({ loadingProducts, pages, products }) => {
	const locale = useLocale()
	const router = useRouter()
	const translation = useTranslation()
	const cart = useCart({ id: router.query.cartId as string })
	const { bookings } = useBookings()
	const m = translation.messages.pages.shop.cart.overview.summary

	const webshopCartCheckoutPage = useMemo(() => getPage("Webshop Cart Checkout Template", locale, pages),[pages, locale])

	const bookingNumber = bookings.find(booking => booking.id === cart.id)?.name

	const total = useMemo(() => formatPrice(
		products.reduce((sum, product) => sum + product.productVariant?.price! * getCartProductAmount(product), 0),
		locale)
	, [products, locale])

	const amount = useMemo(() =>
		products.reduce((sum, product) => sum + getCartProductAmount(product), 0)
	, [products])

	const isEmptyCart = products.length < 1

	return (
		<Card>
			<CardContent>
				<div className={bem()}>
					<div className={bem('booking')}>
						<div>{m.booking}</div>
						<div>{bookingNumber}</div>
					</div>
					<div className={bem('total')}>
						<div>{m.total}</div>
						<div className={bem('total__result')}>
							{loadingProducts ? <Skeleton height={30} width={100} /> : total}
						</div>
					</div>
				</div>
			</CardContent>
			<CardActions className={bem('actions')}>
				{loadingProducts
					? <Skeleton height={40} width={"100%"} />
					: <Button
						disabled={isEmptyCart}
						title={m.checkout}
						variant="contained"
						onClick={async () => await router.push({
							pathname: webshopCartCheckoutPage?.uri as string,
							query: {
								cartId: router.query.cartId
							}
						})}
					/>}
			</CardActions>
		</Card>
	)
}