import LinearProgress from "@mui/material/LinearProgress"
import React, { FC } from "react"

import { Card } from "src/components/common/card"
import { SideNavItemProps, SideNav } from "src/components/common/sideNav"

import { create } from "src/helpers/bem"

import { Category, useCategories } from "src/states/shop/category"
import { mergeFilter, useFilter } from "src/states/shop/filter"
import { TranslationMessages } from "src/translations"

import styles from "./ShopOverviewNavigation.module.scss"

const bem = create(styles, "ShopOverviewNavigation")

export type ShopOverviewNavigationProps = {
  messages: TranslationMessages
}

export const ShopOverviewNavigation: FC<ShopOverviewNavigationProps> = ({
  messages,
}) => {
  const m = messages.pages.shop.overview
  const { loading, categories } = useCategories()
  const { filterBy } = useFilter()

  const onClick = (categoryId: string) => {
    const category = [
      ...categories,
      ...categories.flatMap(c => c.children?.map(subCategory => subCategory))
    ].find(c => c?.id === categoryId) as Category
    mergeFilter({ filterBy: { category } })
  }

  if (loading) {
    return <LinearProgress color="primary" />
  }

  if (!categories || categories?.length === 0) {
    return <></>
  }

  const formattedCategories = categories.map(category => {
    return {
      ...category,
      active:
        filterBy?.category?.id === category.id ||
        filterBy?.category?.parentCategoryId === category?.id ||
        (category?.id === "default" && !filterBy),
      onClick: () => onClick(category.id),
      title: category.name,
      child: category.children?.map(subCategory => ({
        ...subCategory,
        active: filterBy?.category?.id === subCategory.id,
        title: subCategory.name,
        onClick: () => onClick(subCategory.id!),
      })),
    }
  })

  return (
    <div className={bem()}>
      <Card size="small" title={m.title}>
        <SideNav
          items={formattedCategories as ReadonlyArray<SideNavItemProps>}
        />
      </Card>
    </div>
  )
}
