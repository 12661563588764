import { LinearProgress } from "@mui/material"
import { FC, useEffect, useState } from "react"
import { Table, TableData } from "src/components/common/table"
import { create } from "src/helpers/bem"
import { fetchWebshopIncidents } from "src/queriesXRM/incident"
import { TranslationMessages } from "src/translations"
import { xRMApiIncident } from "src/types/xRM"

import styles from "./ShopIncidents.module.scss"

const bem = create(styles, "ShopIncidents")

export type ShopIncidentsProps = {
	messages: TranslationMessages
}

export const ShopIncidents: FC<ShopIncidentsProps> = ({ messages }) => {
	const [ loading, setLoading ] = useState(false)
	const [ incidents, setIncidents ] = useState<xRMApiIncident[]>([])
	const m = messages.pages.shop.incidents

	useEffect(() => {
		setLoading(true)
		const fetchWebshopIncidentsAsync = async () => setIncidents(await fetchWebshopIncidents())
		fetchWebshopIncidentsAsync().finally(() => setLoading(false))
	}, [])

	const renderTable = (data: TableData[]) =>
		<>
			{data.length > 0 ? (
				<div className={bem("table")}>
					<Table
						cellTranslations={m.table}
						data={data}
						messages={messages}
						order="desc"
						orderBy="date"
					/>
				</div>
			) : (
				<p className={bem('nodata')}>
					{m.noData}
				</p>
			)}
		</>

	const formatIncidents = (items: xRMApiIncident[]): TableData[] =>
		items.map(incident => ({
			name: incident.title!,
			order: incident.referenceRecordNumber!,
			type: incident.incidentType,
			status: incident.statecode,
			description: incident.description ?? ""
		}))

	if (loading) return <LinearProgress color="primary" />

	return (
		<div className={bem()}>
			{ renderTable(formatIncidents(incidents)) }
		</div>
	)
}