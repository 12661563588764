import React, { FC } from "react"

import { Card } from "src/components/common/card"
import { DocumentBox, DocumentBoxItem } from "src/components/common/documentBox"

import { create } from "src/helpers/bem"

import { useFilter } from "src/states/shop/filter"
import { TranslationMessages } from "src/translations"

import styles from "./ShopOverviewLinks.module.scss"


const bem = create(styles, "ShopOverviewLinks")

export type ShopOverviewLinksProps = {
  messages: TranslationMessages
}

export const ShopOverviewLinks: FC<ShopOverviewLinksProps> = ({ messages }) => {
  const m = messages.pages.shop.overview.sidebar.links
  const { filterBy } = useFilter()
  const formattedLinks = filterBy?.category?.links?.map((link) => {
    return {
      src: link.path,
      name: link.title,
    }
  })

  if (!formattedLinks || formattedLinks?.length === 0) {
    return null
  }
  return (
    <Card
      classNames={{
        root: bem(),
      }}
    >
      <DocumentBox items={formattedLinks as DocumentBoxItem[]} title={m.title} />
    </Card>
  )
}
